<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4" v-if="currpage == 'list'">
        <b-col>
          <b-card>
            <div>
              <el-button type="primary" @click="add_license">{{
                $t("Add")
              }}</el-button>
            </div>
            <p class="p-3" v-if="licenselist">
              <el-table
                stripe
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="licenselist"
                @row-click="row_clicked_event"
              >
                <el-table-column label="ID#" prop="IDn"> </el-table-column>
                <el-table-column :label="$t('license')" prop="LicenseKey">
                </el-table-column>
                <el-table-column :label="$t('Store')" prop="LocationID">
                </el-table-column>
                <el-table-column :label="$t('Device Number')" prop="Device">
                </el-table-column>
                <el-table-column :label="$t('Status')" prop="Active">
                  <template v-slot="{ row }">
                    <span v-if="row.Active == 1">{{ $t("Yes") }}</span>
                    <span v-else>{{ $t("No") }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Expire Date')" prop="ExpireDate">
                </el-table-column>
              </el-table>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4" v-if="currpage == 'edit'">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
                <b-row>
                  <b-col>
                    <base-input label="license">
                      <b-input
                        placeholder="license"
                        class="form-control"
                        v-model="cur_license.LicenseKey"
                        :disabled="cur_opt == 'update'"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Store')">
                      <select
                        class="form-control"
                        v-model="cur_license.LocationID"
                      >
                        <option
                          v-for="dt in storelist"
                          :key="dt.LocationID"
                          :value="dt.LocationID"
                        >
                          {{ dt.LocationID }} - {{ dt.LocationName }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Status')">
                      <select class="form-control" v-model="cur_license.Active">
                        <option value="0">{{ $t("No") }}</option>
                        <option value="1">{{ $t("Yes") }}</option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Device Number')">
                      <b-input
                        type="number"
                        min="1"
                        max="999"
                        placeholder="Device Number"
                        class="form-control"
                        v-model="cur_license.Device"
                        required
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Expire Date')">
                      <flat-picker
                        slot-scope="{ focus, blur }"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{
                          allowInput: true,
                          dateFormat: 'Y-m-d',
                          altFormat: 'Y-m-d',
                          ariaDateFormat: 'Y-m-d',
                          enableTime: false,
                          minDate: 'today',
                        }"
                        class="form-control datepicker"
                        v-model="cur_license.ExpireDate"
                      >
                      </flat-picker>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_cancel">{{
                      $t("Back")
                    }}</el-button>
                  </b-col>
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_submit">{{
                      $t("Submit")
                    }}</el-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    flatPicker,
  },
  data() {
    return {
      currpage: "list",
      cur_license: null,
      cur_opt: null,
      new_license: {
        LicenseKey: "",
        LocationID: "",
        Device: 1,
        Active: 1,
        ExpireDate: "",
      },
      licenselist: null,

      token: "",
      user_id: 0,
    };
  },
  methods: {
    add_license() {
      this.currpage = "edit";
      this.cur_license = this.new_license;
      this.cur_opt = "add";
    },
    row_clicked_event(row, event, column) {
      this.currpage = "edit";
      this.cur_license = JSON.parse(JSON.stringify(row));
      this.cur_opt = "update";
    },
    form_cancel() {
      this.currpage = "list";
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_license) {
        if (this.cur_license[key])
          bodyFormData.append(key, this.cur_license[key]);
      }

      axios({
        method: "post",
        url: "/Api/Web/License/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_license_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/License",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.licenselist = rt.data.licenses;
              that.storelist = rt.data.stores;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            this.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      var currentDate = new Date();
      currentDate.setFullYear(currentDate.getFullYear() + 1);
      this.new_license.ExpireDate = currentDate.toJSON().slice(0, 10);
      // console.log(currentDate.toJSON().slice(0,10).replace(/-/g,'/'));

      return this.get_license_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
